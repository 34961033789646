import React from "react";
import ContactSection from "./contactSection";
import GallerySection from "./gallerySection";
import LandingSection from "./landingSection";
import MenuSection from "./menuSection";
import KaraokeSection from "./karaokeSection"

export function Homepage(props){
    return (
        <div id="homePage">
            <LandingSection />
            <KaraokeSection />
            <MenuSection />
            <GallerySection />
            <ContactSection />
            <div className='kpo'>
                <div className='kpo-content'>
                    <div className='kpo-text'>
                        <p>Z przyjemnością informujemy, że cały czas rozwijamy się dla Was!</p>
                        <p>W chwili obecnej firma MG Maciej Gorczycki realizuje projekt: Dywersyfikacja i rozszerzenie działalności gastronomicznej w regionie 2 (woj. mazowieckie): rozwój nowoczesnej kuchni wegańskiej. Dzięki projektowi firma będzie mogła dywersyfikować swoją działalność i lepiej uodpornić się na ewentualne kryzysy.</p>
                        <p>Dofinansowanie projektu z UE:  302 051.69 zł</p>
                    </div>
                    <div className='kpo-image'>
                        <img src='/picture/kpo/plansza_informacyjna_kpo_poziom.png' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}